.CoursesContainer {
    width: 100vw;
    background: #FFF;
    padding:0rem 4rem;
   
}

.bg_overlay{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    min-width: 55%;
    height: 100%;
    top: 0;
    clip-path: polygon(0 0,100% 0%,75% 100%,0 100%);
    line-height: 2.7rem;
}

.bg_overlay_contact{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    min-width: 55%;
    height: 100%;
    top: 0;
    clip-path: polygon(0 0,100% 0%,75% 100%,0 100%);
    line-height: 2.7rem;
}

.bg_overlay_contact > h1 {
    font-size: 2.1rem;
    font-weight: 400;
    color:#051f7b;
}

.bg_overlay_master{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    min-width: 55%;
    height: 100%;
    top: 0;
    clip-path: polygon(0 0,100% 0%,75% 100%,0 100%);
    line-height: 2.7rem;
}

.bg_overlay_tests {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    min-width: 55%;
    height: 100%;
    top: 0;
    clip-path: polygon(0 0,100% 0%,75% 100%,0 100%);
    line-height: 2.7rem;
}

.bg_overlay_tests > h1 {
    font-size: 2.1rem;
    font-weight: 400;
    color:#051f7b;
}

.bg_overlay_master > h1 {
    font-size: 2.1rem;
    font-weight: 400;
    color:#051f7b;
}

.bg_overlay > h1{
    font-size: 2.1rem;
    font-weight: 400;
    color:#051f7b;
    
}



.row {
   display: grid;
   width: 100%;
   grid-template-columns: 60% 40%;
   padding-bottom: 7rem;
  
}
.leftSide{
    display: grid;
    grid-template-columns:  repeat(auto-fill, minmax(200px, 1fr));
    align-items: center;
    padding: 5rem 3rem 8rem 2rem;
    gap: 2rem;

}

.course{
    background-color: #fff;
    color: #051f7b;
    width: 13.375rem;
    height: 8rem;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 0.3125rem;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    gap: .3125rem;
    border: .0938rem solid rgb(235, 237, 240);
}

.university_course{
    background-color: #fff;
    color: #051f7b;
    width: 13.375rem;
    height: 11rem;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 0.3125rem;
    justify-content:flex-start;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: .3125rem;
    border: .0938rem solid rgb(235, 237, 240);
}

.university_course:hover{
    border: .0938rem solid #0027d3;
    background:#0330f8;
    color: white;
    transition: all 0.3s ease;
}
.course:hover{
    border: .0938rem solid #0027d3;
    background:#0330f8;
    color: white;
    transition: all 0.3s ease;
}

.university_course > :nth-child(2){
    font-size: 1.1rem;
    font-weight: 500;
}

.course > :nth-child(2){
    font-size: 1.1rem;
    font-weight: 500;
}
.university_course > img{
    vertical-align: middle;
    width: 2.4rem;
    height: 3rem;
    
}
.course > img {
    vertical-align: middle;
    width: 2.4rem;
    height: 3rem;
    
}

.rightSide{
    display: flex;
    height: 100%;
    padding-left: .5rem;
}

.repair_main_banner {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 2.5rem 0;
}
.repair_main_banner h2{
    font-size: 1.8rem;
    color: #fff;
    margin-left: 2.3rem;
    padding-bottom: 1rem;

}
.progress {
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 27.5%;
    margin-left: 2.3rem;
}

.progress_university {
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 18%;
    margin-left: 2.3rem;
}
.progress_master {
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 11.3%;
    margin-left: 2.3rem;
}

.progress_tests{
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 24.7%;
    margin-left: 2.3rem;
}

.progress_bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);

}


/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 768px) {
    .bg_overlay > h1{
        font-size: 1.8rem;
        font-weight: 400;
        color:#051f7b;
        padding-right: 2rem;
        
    }
 
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 640px) {
    .bg_overlay > h1{
        font-size: 1.5rem;
        font-weight: 400;
        color:#051f7b;
        
    }

    .leftSide{
        padding: 5rem;
        gap: 2rem;
    
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:1024px) {
    .progress {
        width: 40.5%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 26.5%;
        margin-left: 2.3rem;
    }

    .bg_overlay_master > h1{
        font-size: 1.6rem;
        font-weight: 500;
        color:#051f7b;
    }

    .bg_overlay_tests > h1 {
        font-size: 1.6rem;
        font-weight: 500;
        color:#051f7b;
    }
    .progress_master {
        width: 16.2%;
        margin-left: 2.3rem;
    }

    .progress_tests{
        width: 35%;
        margin-left: 2.3rem;
    }
    
}


/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:768px) {
    .progress {
        width: 54%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 35%;
        margin-left: 2.3rem;
    }
    .bg_overlay_master > h1{
        font-size: 1.3rem;
        font-weight: 500;
        color:#051f7b;
    }
    .bg_overlay_tests > h1 {
        font-size: 1.3rem;
        font-weight: 500;
        color:#051f7b;
    }

    .progress_master {
        width: 22%;
        margin-left: 2.3rem;
    }
    .progress_tests{
        width: 46.5%;
        margin-left: 2.3rem;
    }
}


/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:640px) {
    .progress {
        width: 64%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 42%;
        margin-left: 2.3rem;
    }
    .bg_overlay_master > h1{
        font-size: 1.1rem;
        font-weight: 500;
        color:#051f7b;
     
    }
    .progress_master {
        width: 26%;
        margin-left: 2.3rem;
    }

    .progress_tests{
        width: 56.2%;
        margin-left: 2.3rem;
    }
    
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 630px) {
    .bg_overlay > h1{
        font-size: 1.5rem;
        font-weight: 400;
        color:#051f7b;
        
    }
    .bg_overlay_master > h1{
        font-size: 1rem;
        font-weight: 500;
        color:#051f7b;
     
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 530px) {
    .bg_overlay{
        line-height: 2rem;
    }
    .bg_overlay > h1{
        font-size: 1.3rem;
        font-weight: 400;
        color:#051f7b;
        
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(160px, 1fr));
        padding: 5rem;
    }
    .course{
        width: 11rem;
        height: 7rem;
    }
    .progress {
        width: 78%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 51%;
        margin-left: 2.3rem;
    }


    .university_course{
        width: 11.375rem;
        height: 11rem;
        padding: 1rem;
        gap: .3125rem;
    }
    .bg_overlay_master > h1{
        font-size: .91rem;
        font-weight: 600;
        color:#051f7b;
     
    }
    .bg_overlay_tests > h1 {
        font-size: 1rem;
        font-weight: 600;
        color:#051f7b;
    }
    .progress_master {
        width: 31.5%;
        margin-left: 2.3rem;
    }
    .progress_tests{
        width: 67.5%;
        margin-left: 2.3rem;
    }
    
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 530px) {

    .leftSide{
        padding: 3rem 3.2rem 3rem 2.2rem;
    }
    .course{
        width: 13.3rem;
        height: 7.5rem;
    }
    .bg_overlay_master{
        line-height: 1.2rem;
        padding-right: 1rem;
    }
    .bg_overlay_master > h1{
        font-size: .78rem;
        font-weight: 600;
        color:#051f7b;
     
    }
        .bg_overlay_tests {

        line-height: 1.3rem;
    }
    
    .bg_overlay_tests > h1 {
        font-size: .75rem;
        margin-right: 1rem;
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 500px) {
    .bg_overlay{
        line-height: 2rem;
    }
    .bg_overlay > h1{
        font-size: 1.2rem;
        font-weight: 400;
        color:#051f7b;
        
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(150px, 1fr));
        padding: 5rem;
    }
    .course{
        width: 11rem;
        height: 7rem;
    }
    .progress {
        width: 82%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 54%;
        margin-left: 2.3rem;
    }
    .university_course{
        width: 11rem;
        height: 11rem;
        padding: 1rem;
        gap: .3125rem;
    }
    .bg_overlay_master > h1{
        font-size: .86rem;
        font-weight: 600;
        color:#051f7b;
     
    }
    .progress_master {
        width: 33.1%;
        margin-left: 2.3rem;
    }
    .progress_tests{
        width: 71.5%;
        margin-left: 2.3rem;
    }
    .repair_main_banner h2{
        font-size: 1.8rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .leftSide{
        padding: 3rem 3.2rem 3rem 2.2rem;
    }
    .course{
        width: 13.3rem;
        height: 7.5rem;
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 430px) {
    .repair_main_banner h2{
        font-size: 1.55rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(100px, 1fr));
        padding: 3rem 3.2rem 3rem 2.2rem;
    }
    .course{
        width: 11rem;
        height: 7.5rem;
    }
    .bg_overlay_master{
        line-height: 1.2rem;
        padding-right: 1rem;
    }
    .bg_overlay_master > h1{
        font-size: .78rem;
        font-weight: 600;
        color:#051f7b;
     
    }
    .bg_overlay_tests {

        line-height: 1.3rem;
    }
    
    .bg_overlay_tests > h1 {
        font-size: .75rem;
        margin-right: 1rem;
    }


}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 428px) {
    .repair_main_banner h2{
        font-size: 1.55rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(100px, 1fr));
        padding: 3rem 3.2rem 3rem 2.2rem;
    }
    .course{
        width: 11rem;
        height: 7.5rem;
    }
    .bg_overlay_tests {

        line-height: 1.3rem;
    }
    
    .bg_overlay_tests > h1 {
        font-size: .75rem;
        margin-right: 1rem;
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 425px) {
    .repair_main_banner h2{
        font-size: 1.55rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .bg_overlay{
        line-height: 1.8rem;
    }
    .bg_overlay > h1{
        font-size: 1.1rem;
        font-weight: 400;
        color:#051f7b;
        
    }

    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(100px, 1fr));
        padding: 3rem 3.2rem 3rem 2.2rem;
    }
    .course{
        width: 11rem;
        height: 7rem;
    }
    .progress {
        width: 82%;
        margin-left: 2.3rem;
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(120px, 1fr));
        padding: 3rem 2.5rem 3rem 1.1rem;
    }
    .course{
        width: 11.88rem;
        height: 7rem;
    }
    .progress {
        width: 82%;
        margin-left: 2.3rem;
    }

    .progress_university {
        width: 63.3%;
        margin-left: 2.3rem;
    }
    .university_course{
        width: 11.8rem;
        height: 11rem;
        padding: 1rem;
        gap: .3125rem;
    }
    .bg_overlay_master > h1{
        font-size: .7rem;
        font-weight: 600;
        color:#051f7b;
     
    }
    .bg_overlay_tests > h1 {
        font-size: .82rem;
        font-weight: 600;
        color:#051f7b;
    }
    .progress_master {
        width: 39%;
        margin-left: 2.3rem;
    }
    .progress_tests{
        width: 83.5%;
        margin-left: 2.3rem;
    }
}


/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 414px) {
    .repair_main_banner h2{
        font-size: 1.5rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(120px, 1fr));
        padding: 3rem 2.7rem 3rem 1.5rem;
    }
    .course{
        width: 11.3rem;
        height: 7rem;
    }
    .university_course{
        width: 11rem;
        height: 12.5rem;
    }
    .bg_overlay_master{
        line-height: 1.2rem;
        padding-right: 1rem;
    }
    .bg_overlay_tests {

        line-height: 1.3rem;
    }
    
    .bg_overlay_tests > h1 {
        font-size: .75rem;
        margin-right: 1rem;
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 393px) {
    .repair_main_banner h2{
        font-size: 1.4rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(120px, 1fr));
        padding: 3rem 2.7rem 3rem 1.5rem;
    }
    .course{
        width: 10.6rem;
        height: 7rem;
    }
    .university_course{
        width: 10.5rem;
        height: 12.5rem;
    }
    .bg_overlay_master{
        line-height: 1.2rem;
        padding-right: 1rem;
    }
    .bg_overlay_tests {

        line-height: 1.3rem;
    }
    
    .bg_overlay_tests > h1 {
        font-size: .75rem;
        margin-right: 1rem;
    }

    
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 375px) {
    .repair_main_banner h2{
        font-size: 1.35rem;
        color: #fff;
        margin-left: 2rem;
        padding-bottom: 1rem;
    
    }
    .bg_overlay{
        line-height: 1.5rem;
    }
    .bg_overlay > h1{
        font-size: .9rem;
        font-weight: 400;
        color:#051f7b;
        
    }

    .leftSide{
        grid-template-columns:  repeat(auto-fill, minmax(120px, 1fr));
        padding: 3rem 2.7rem 3rem 1.5rem;
    }
    .course{
        width: 9.6rem;
        height: 7rem;
    }
    .progress {
        width: 82%;
        margin-left: 2.3rem;
    }
    .progress_university {
        width: 57.1%;
        margin-left: 2.3rem;
    }
    .university_course{
        width: 10.6rem;
        height: 11rem;
        padding: 1rem;
        gap: .3125rem;
    }

    .bg_overlay_master > h1{
        font-size: .63rem;
        font-weight: 600;
        color:#051f7b;
     
    }

    .bg_overlay_tests > h1 {
        font-size: .76rem;
        font-weight: 600;
        color:#051f7b;
    }
    .progress_master {
        width: 34.6%;
        margin-left: 2.3rem;
    }

    .progress_tests{
        width: 74.5%;
        margin-left: 2.3rem;
    }
    .university_course{
        width: 9.7rem;
        height: 12.5rem;
    }

.bg_overlay_tests {

    line-height: 1.3rem;
}

.bg_overlay_tests > h1 {
    font-size: .75rem;
    margin-right: 1rem;
}
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width: 360px) {

    .repair_main_banner h2{
        font-size: 1.25rem;
        color: #fff;
        margin-left: 2.3rem;
        padding-bottom: 1rem;
    
    }
}





