.Virtual {
    /* background: linear-gradient(180deg, rgba(53,49,255,1) 0%, rgba(0,71,255,1) 100%); */
    /* background: linear-gradient(180deg, rgba(199,225,255,1) 0%, rgba(191,232,255,1) 100%); */
    /* background: linear-gradient(180deg, rgba(231,245,246,1) 0%, rgba(231,245,246,1) 100%); */
    /* background: linear-gradient(180deg, rgba(3,20,79,1) 0%, rgba(3,20,79,1) 100%);
     */
     background: linear-gradient(90deg, rgba(3,20,79,1) 0%, rgba(5,30,114,1) 50%, rgba(3,20,79,1) 100%);
    margin-top: 6rem;
    /* margin-bottom: 6rem; */
    padding: 3.2rem 2.7rem 3.2rem 2.7rem;
    display: flex;
    justify-content: space-around;
    color: #fff;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.left > :nth-child(1) {
    font-size: 1.8rem;
    text-transform:capitalize;
    display: block;
    font-weight: bold;

}
.left > :nth-child(2) {
    font-size: 1.3rem;
    /* text-transform: uppercase; */
    display: block;
    width: 20rem;
}

.left > :nth-child(3) {
    font-size: 1.4rem;
    font-weight: bold;
}

.left > img {
    position: absolute;
    width: 7.5rem;
    bottom: -1.7rem;
    left: 9.3rem;
}

.wrapper {
    width: 24rem;
    margin-left: 3rem;
}


@media screen and (max-width:1366px){
    
    .left > img {
        position: absolute;
        width: 6rem;
        bottom: -1.4rem;
        left: 10.6rem;
    }
}

@media screen and (max-width:768px){
    .left > :nth-child(1) {
        font-size: 1.6rem;
        text-transform:capitalize;
        display: block;
        font-weight: bold;
    
    }

    .left > :nth-child(2) {
        font-size: 1.1rem;
        display: block;
    }
    
    .left > img {
        position: absolute;
        width: 7rem;
        bottom: -1.7rem;
        left: 10.3rem;
    }
    .wrapper {
        width: 17rem;
        margin-left: 3rem;
    }
}

@media screen and (max-width:640px){
    .Virtual{
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 2rem 5rem 2rem;
        text-align: center;
    }

    .left > :nth-child(2) {
        font-size: 1.1rem;
        display: block;
        width: 30rem;
    }

    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.3rem;
        left: 17.8rem;
    }
    .wrapper {
        width: 22rem;
        margin-top: 3rem;
    }
}
/* ===================================== */
@media screen and (max-width:430px){
    .Virtual{
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 2rem 5rem 2rem;
        text-align: center;
    }
    .left > :nth-child(2) {
        font-size: 1.1rem;
        display: block;
        width: 20rem;
    }
    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.6rem;
        left: 13rem;
    }

    .wrapper {
        width: 21rem;
        margin-top: 3rem;
        margin-right: 2rem;
    }
}
/* ==================================== */
@media screen and (max-width:425px){
    .left > :nth-child(1) {
        font-size: 1.3rem;
        text-transform:capitalize;
        display: block;
        font-weight: bold;
    
    }

    .left > :nth-child(2) {
        font-size: .95rem;
        display: block;
        width: 22rem;
    }
    .left > :nth-child(3) {
        font-size: 1.3rem;
        text-transform:capitalize;
        display: block;
        font-weight: bold;
    
    }

    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.8rem;
        left: 11.8rem;
    }
    .wrapper {
        width: 19rem;
        margin-top: 3rem;
        margin-right: 2rem;
    }
}
@media screen and (max-width:414px){
    .left > :nth-child(1) {
        font-size: 1.4rem;
    }
    .left > :nth-child(2) {
        font-size: 1rem;
        display: block;
        width: 22rem;
    }
    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.7rem;
        left: 10.8rem;
    }
    .wrapper {
        width: 19rem;
        margin-top: 3rem;
        margin-right: 1.5rem;
    }
}
@media screen and (max-width:412px){
    .left > :nth-child(1) {
        font-size: 1.4rem;
    }
    .left > :nth-child(2) {
        font-size: 1rem;
        display: block;
        width: 22rem;
    }
    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.7rem;
        left: 10.8rem;
    }
    .wrapper {
        width: 20rem;
        margin-top: 3rem;
        margin-right: 1.5rem;
    }
}

@media screen and (max-width:375px){
    .left > :nth-child(2) {
        font-size: .9rem;
        display: block;
        width: 20rem;
    }
    .left > img {
        position: absolute;
        width: 6.3rem;
        bottom: -1.3rem;
        left: 10.8rem;
    }
    .wrapper {
        width: 18rem;
        margin-top: 3rem;
        margin-right: 1.5rem;
    }
}

