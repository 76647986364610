.container {
    width: 100vw;
    padding: 1rem 2rem 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    overflow: hidden;
}

.wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    /* overflow-x: hidden; */
}

.wrapper > :nth-child(6){
    display: none;
}

.wrapper > :nth-child(7){
    display: none;
}

.wrapper > :nth-child(3){
    display: none;
}
.wrapper > :nth-child(4){
    display: none;
}

.wrapper > img {
    width: 28.15rem;
    position: absolute;
    /* border-radius: 40%; */
    bottom: -.5rem;
    z-index: -1;
}

.whiteCircle{
    width: 26.7rem;
    height: 26.8rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    background:  linear-gradient(138deg, rgb(72, 161, 255) 36.7%, rgb(0, 8, 187) 84.4%, rgb(255, 255, 255) 119.7%);
    border: 0px solid rgb(7, 91, 173);
    /* border: 24px solid  */
}



.cart2 {
    position: absolute;
    bottom: 23%;
    left: 72%;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #0362ce;
}
.signup:hover{
    background-color:#0362ce;
    color: white;
}

.cart2 > svg {
    width: 3rem;
    height: 3rem;
    background: white;
    padding: 10px;
    border-radius: 50%;
    border: .2rem solid #0362ce;
}
.arrow > svg {
    color: "blue";
    font-size: "1.5rem";
}
.arrow:hover > svg {
    color: white;
   
}

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    background: #fff;
    padding: 10px;
    font-size: .8rem;
    border-radius: .9375rem;
    box-shadow: var(--shadow1);
}

.signup > :first-child {
    display: block;
    width: 6rem;
    font-size: 1rem;
}


.signup > :nth-child(2) {
    border-radius: 50%;
    border: 1px solid rgb(44, 119, 249);
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;
    align-items: center;
    justify-content: center;
}
.wrapper > :nth-child(10){
    display: none;
}
.wrapper > :nth-child(9){
    display: none;
}
.wrapper > :nth-child(11){
    display: none;
}
.wrapper > :nth-child(8){
    display: none;
}
.wrapper > :nth-child(12){
    display: none;
}
.wrapper > :nth-child(13){
    display: none;
}
.wrapper > :nth-child(15){
    display: none;
}
.wrapper > :nth-child(16){
    display: none;
}
.wrapper > :nth-child(14){
    display: flex;
    top: 23rem;
    z-index: 999;
}

/* Sides of Hero Page */

.h_sides {
    display: grid;
    position: relative;
    grid-template-rows: 1fr 1fr;
    margin-right: 0rem;
    margin-left: 1.5rem;
    padding-top: 2.5rem;
    color: #fff;
    overflow: hidden;
}


.text1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}

.text2 {
    display: flex;
    flex-direction: column;
    width: min-content;
}

.text2 > :first-child {
    font-weight: 800;
    font-size: 2.5rem;
}

.text2 > :nth-child(2) {
    display: block;
}

.traffic {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1rem;
}

.traffic > :first-child {
    font-weight: 800;
    font-size: 2.5rem;
}

.customers {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1rem;

}

.customers > :first-child {
    font-weight: 800;
    font-size: 2.5rem;
}

/****************************************    MEDIA QUERY for max-width 1024px        ***********************************************************/
@media screen and (max-width: 1366px) {
    .wrapper{
        width: 27.15rem;
        right: -12rem;
    }
    .h_sides {
        display: grid;
        position: relative;
        grid-template-rows: 1fr 1fr;
        margin-right: 1rem;
        margin-left: 1.5rem;
        padding-top: 2.5rem;
        color: #fff;
        overflow: hidden;
        width: 16.5rem;
    }
    .wrapper > img {
        width: 23.15rem;
        height: 25.8rem;
        position: absolute;
        border-radius: 35%;
        right: 1.75rem;
    }
    
    .whiteCircle{
        width: 23.7rem;
        height: 24.8rem;
        border-radius: 50%;
        z-index: -99;
        position: absolute;
        right: 2.5rem;
        background:  linear-gradient(138deg, rgb(72, 161, 255) 36.7%, rgb(0, 8, 187) 84.4%, rgb(255, 255, 255) 119.7%);
        border: 0px solid rgb(7, 91, 173);
    }
     
    .wrapper > :nth-child(15){
        display: flex;
        top: 23rem;
        z-index: 999;
    }
    .wrapper > :nth-child(14){
        display: none;
    }

    .cart2 {
        position: absolute;
        bottom: 1.25%;
        right: -20%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
}

/****************************************    MEDIA QUERY for max-width 1024px        ***********************************************************/
@media screen and (max-width: 1024px) {
    .wrapper{
        width: 30.15rem;
        right: 0rem;
    }
    .container {
        width: 100%;
        padding: 3rem 1.1rem;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .wrapper {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: relative;
    }
    
    .wrapper > img {
        width: 23.15rem;
        height: 25.8rem;
        position: absolute;
        border-radius: 35%;
        right: 1.75rem;
    }
    
    .whiteCircle{
        width: 23.7rem;
        height: 24.8rem;
        border-radius: 50%;
        z-index: -99;
        position: absolute;
        right: 2.5rem;
        background:  linear-gradient(138deg, rgb(72, 161, 255) 36.7%, rgb(0, 8, 187) 84.4%, rgb(255, 255, 255) 119.7%);
        border: 0px solid rgb(7, 91, 173);
    }

    .cart2 {
        position: absolute;
        bottom: 2%;
        right: -20%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
    .wrapper > :nth-child(7){
        display: none;
    }
    .h_sides {
        display: grid;
        position: relative;
        grid-template-rows: 1fr 1fr;
        margin-right: 1rem;
        margin-left: 1.5rem;
        padding-top: 2.5rem;
        color: #fff;
        overflow: hidden;
        width: 14.5rem;
    }
    
    
    .text1 {
        text-transform: uppercase;
        font-size: 1.5rem;
        width: min-content;
        display: block;
        font-weight: 600;
    }
    
    .text2 {
        display: flex;
        flex-direction: column;
        width: min-content;
    }
    
    .text2 > :first-child {
        font-weight: 700;
        font-size: 2rem;
    }
    
    .text2 > :nth-child(2) {
        display: block;
    }
    
    .traffic {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    
    .traffic > :first-child {
        font-weight: 700;
        font-size: 2rem;
    }
    .traffic > :nth-child(2){
        font-size: .85rem;
    }
    .customers {
        display: flex;
        flex-direction: column;
        text-align: right;
    
    }
    
    .customers > :first-child {
        font-weight: 700;
        font-size: 2rem;
    }
    .customers > :nth-child(2){
        font-size: .85rem;
    }
  


    .wrapper > :nth-child(15){
        display: none;

    }

    .wrapper > :nth-child(16){
        display: flex;
        top: 23rem;
        z-index: 999;
    }

    
}

/****************************************    MEDIA QUERY for max-width 820px        ***********************************************************/
@media screen and (max-width: 820px) {

    .container {
        width: 100%;
        padding: 1rem .6rem 1rem 1rem;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .wrapper {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: relative;
    }
    .wrapper > :nth-child(7){
        display: none;
    }
    .wrapper > img {
        width: 80.15rem;
        height: 23.15rem;
        position: absolute;
        border-radius: 35%;
        right: .5rem;
    }
    
    .whiteCircle{
        width: 22.7rem;
        height: 24rem;
        border-radius: 50%;
        z-index: -99;
        position: absolute;
        left: -.2rem;
        background:  linear-gradient(138deg, rgb(72, 161, 255) 36.7%, rgb(0, 8, 187) 84.4%, rgb(255, 255, 255) 119.7%);
        border: 0px solid rgb(7, 91, 173);
    }

    .cart2 {
        display: flex;
        width: 14rem;
        position: absolute;
        bottom: 2%;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }

    .h_sides {
        display: grid;
        position: relative;
        grid-template-rows: 1fr 1fr;
        margin-right: 1.2rem;
        margin-left: 1rem;
        padding-top: 2.5rem;
        color: #fff;
        overflow: hidden;
        width: 11.8rem;
    }
    
    
    .text1 {
        text-transform: uppercase;
        font-size: 1.5rem;
        width: min-content;
        display: block;
        font-weight: 600;
    }
    
    .text2 {
        display: flex;
     
        flex-direction: column;
        width: min-content;
    }
    
    .text2 > :first-child {
        font-weight: 700;
        font-size: 1.8rem;
    }
    
    .text2 > :nth-child(2) {
        display: block;
        font-size: .85rem;
    }
    
    .traffic {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    
    .traffic > :first-child {
        font-weight: 600;
        font-size: 2.1rem;
    }
    .traffic > :nth-child(2){
        font-size: .85rem;
    }
    
    .customers {
        display: flex;
        flex-direction: column;
        text-align: right;
    
    }
    
    .customers > :first-child {
        font-weight: 600;
        font-size: 2.1rem;
    }
    .customers > :nth-child(2){
        font-size: .85rem;
    }
    
}

/* =============================================================      media screen max 768  ================== */

@media screen and (max-width: 768px) {
    .text1{
        font-size: 1.65rem;
    }
    .h_sides {
        display: grid;
        position: relative;
        grid-template-rows: 1fr 1fr;
        margin-right: 1.2rem;
        margin-left: 1rem;
        padding-top: 2.5rem;
        color: #fff;
        overflow: hidden;
        width: 12.8rem;
    }
    .text2 > :nth-child(1) {
        font-size: 2.5rem;
    }
     .text2 > span:nth-of-type(2){
        font-size: 1.1rem;
        text-align: justify;
    }

    .text2 > span:nth-of-type(1),
    .traffic > span:nth-of-type(1),
    .customers > span:nth-of-type(1){
        font-size: 1.5rem;
        text-align: left;
        
    }

    .whiteCircle{
        position: absolute;
        width: 75%;
        height: 88%;
        left:6.75rem;
    }

    .wrapper > img {
        width: 75%;
        height: 100%;
        left: 7.3rem;
    }

    .wrapper > :nth-child(5){
        display: none;
    }
    .wrapper > :nth-child(6){
        display: flex;
        top: 20rem;
    }
    .wrapper > :nth-child(7){
        display: none;
    }
    .cart2 {
        position: absolute;
        bottom: -9%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
    
    .cart2 > svg {
        width: 3rem;
        height: 3rem;
        background: white;
        padding: 10px;
        border-radius: 50%;
        border: .2rem solid #0362ce;
    }


    .container {
        width: 100vw;
        grid-template-areas:
        'left center center'
        'right right right';
        overflow-x: hidden;
    }

    .container > :first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }
    .container > :nth-child(2){
        grid-area: center;

    }
    .container > :nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
        width: 100%;
    }
    .wrapper > :nth-child(9){
        display: none;
     
    }
    .wrapper > :nth-child(10){
        display: none;
    }
    .wrapper > :nth-child(11){
        display: none;
      
    }
    .wrapper > :nth-child(12){
        display: none;
    }
    .wrapper > :nth-child(8){
        display: none;
      
    }
    .wrapper > :nth-child(13){
        display: flex;
        top: 26rem;
        z-index: 999;
    }
    .wrapper > :nth-child(15){
        display: none;
    }
    .wrapper > :nth-child(14){
        display: none;
    }
    .wrapper > :nth-child(16){
        display: none;
    }
}

/* =============================================================      media screen max 640  ================== */

@media screen and (max-width: 640px) {

    .container {
        padding-top: 0;
        padding-right: 1rem;
        overflow: hidden;
    }
    .wrapper {
        padding-top: 0;
    }

    .whiteCircle{
        position: absolute;
        width: 81%;
        height: 75%;
        top: -1rem;
        left:3.4rem;
    }
    .wrapper > :nth-child(7){
        display: none;
    }

    .wrapper > img {
        width: 75%;
        height: 90%;
        left: 5.2rem;
        top: -1.25rem;
    }

    .cart2 {
        position: absolute;
        bottom: 14.25%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
    .container > :nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2.5rem 0rem 0rem 0rem;
        width: 100%;
    }


    .wrapper > :nth-child(1){
        display: none;
    }
    .wrapper > :nth-child(2){
        display: none;
    }
    
    .wrapper > :nth-child(3){
        display: flex;
    }
    .wrapper > :nth-child(4){
        display: flex;
    }
    .wrapper > :nth-child(9){
        display: none;
     
    }
    .wrapper > :nth-child(10){
        display: none;
    }
    .wrapper > :nth-child(11){
        display: flex;
        top: 26rem;
        z-index: 999;
    }
    .wrapper > :nth-child(12){
        display: none;
    }
    .wrapper > :nth-child(8){
        display: none;
      
    }
    .wrapper > :nth-child(13){
        display: none;
        top: 26rem;
    }
    .wrapper > :nth-child(15){
        display: none;
    }
    .wrapper > :nth-child(14){
        display: none;
    }
 
    
}
@media screen and (max-width: 630px) {
    .wrapper > :nth-child(9){
        display: none;
     
    }
    .wrapper > :nth-child(10){
        display: none;
    }
    .wrapper > :nth-child(11){
        display: flex;
        top: 26rem;
        z-index: 999;
    }
    .wrapper > :nth-child(12){
        display: none;
    }
    .wrapper > :nth-child(8){
        display: none;
      
    }
    .wrapper > :nth-child(13){
        display: none;
        top: 26rem;
    }
}

@media screen and (max-width: 530px) {
    .wrapper > img {
        width:70%;
        height: 75%;
        left: 5.2rem;
        top: -1.25rem;
    }
    .whiteCircle{
        position: absolute;
        width: 85%;
        height: 60%;
        top: -1rem;
        left:3.4rem;
    }
    .wrapper > :nth-child(7){
        display: none;
    }
    .wrapper > :nth-child(9){
        display: flex;
        top: 26rem;
    }
    .wrapper > :nth-child(10){
        display: none;
    }
    .wrapper > :nth-child(11){
        display: none;
    }
    .wrapper > :nth-child(12){
        display: none;
    }
    .wrapper > :nth-child(8){
        display: none;
      
    }
    .wrapper > :nth-child(13){
        display: none;
        top: 26rem;
        z-index: 999;
    }
    .wrapper > :nth-child(15){
        display: none;
    }
    .wrapper > :nth-child(14){
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .whiteCircle{
        position: absolute;
        width: 83%;
        height: 60%;
        top: -1rem;
        left:3.4rem;
    }

    .wrapper > img {
        width:65%;
        height: 75%;
        left: 5.2rem;
        top: -1.25rem;
    }

    .cart2 {
        position: absolute;
        bottom: 30.25%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
    .wrapper > :nth-child(7){
        display: none;
    }
    .wrapper > :nth-child(9){
        display: none;
    }
    .wrapper > :nth-child(10){
        display: none;
    }
    .wrapper > :nth-child(11){
        display: none;
    }
    .wrapper > :nth-child(12){
        display: none;
    }
    .wrapper > :nth-child(8){
        display: flex;
        top: 26rem;
    }
    .wrapper > :nth-child(13){
        display: none;
        top: 26rem;
        z-index: 999;
    }
    .wrapper > :nth-child(15){
        display: none;
    }
    .wrapper > :nth-child(14){
        display: none;
    }

}

@media screen and (max-width: 430px) {
    .whiteCircle{
        position: absolute;
        width: 82%;
        height: 53%;
        top: -1rem;
        left:2rem;
    }
    .h_sides {
        width: 10rem;
    }
    .wrapper{
        width: 14rem;
    }
    .wrapper > img {
        width:78.5%;
        height: 65%;
        left: 2.5rem;
    }

    .cart2 {
        position: absolute;
        bottom: 34.25%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;

    }
    .signup > :first-child {
        display: block;
        width: 5rem;
        font-size: .875rem;
        height: 2.4rem;
    }
    .signup > :first-child{
        font-size: .85rem;
    }

.cart2 > svg {
    width: 3rem;
    height: 3rem;
    background: white;
    padding:10px;
    border-radius: 49%;
    border: .18rem solid #0362ce;

}

    .wrapper > :nth-child(6){
        display: none;
    }
    .wrapper > :nth-child(7){
        display: flex;
    }
    .wrapper > :nth-child(8){
        display: none;
    }
    .wrapper > :nth-child(9){
        display: flex;
        top: 23rem;
    }

}

@media screen and (max-width: 425px) {

    .container {
        padding: 0rem 1.2rem 0rem 1rem;
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    .container {
        grid-template-areas:
        'left center'
        'right right';
    }

    .container > :first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 4rem;
    }
    .container > :nth-child(2){
        grid-area: center;

    }

    .wrapper > :nth-child(6){
        display: flex;
        top: 15rem;
        color: #0362ce;

    }
    .wrapper > :nth-child(9){
        top: 21rem;
        z-index: 999;
    }
    .signup{
        display: flex;
        gap: 0;
        align-items: center;
        background: #fff;
        padding: 7px;
        font-size: .8rem;
        border-radius: .9375rem;
        box-shadow: var(--shadow1);
    }
.signup > :first-child {
        font-size: .83rem;

    }

    .cart2 {
        position: absolute;
        bottom: 15.25%;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: #0362ce;
    }
  

    .wrapper > :nth-child(6){
        display: none;
    }


    .text1{
        font-size: 1rem;
    }
 
     .text2 > span:nth-of-type(2){
        font-size: .75rem;
        text-align: justify;
    }

    .text2 > span:nth-of-type(1),
    .traffic > span:nth-of-type(1),
    .customers > span:nth-of-type(1){
        font-size: 1.5rem;
        text-align: left;
        
    }
 

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    
    .wrapper > img {
        width: 16.15rem;
        height: 16rem;
        margin-top: .6rem;
        position: absolute;
        border-radius: 30%;
        left: -0.4rem;

    }
    .wrapper > :nth-child(3){
        position: absolute;
        top: 18rem;
        left: 0.2rem;
        width: 13rem;
        height: 14rem;
    }

}

@media screen and (max-width: 414px) {
    
    .wrapper > img {
        width: 16.15rem;
        height: 16rem;
        margin-top: .6rem;
        position: absolute;
        left: -1.3rem;

    }

    .wrapper > :nth-child(3){
        position: absolute;
        left: -.8rem;
        width: 13rem;
        height: 14.8rem;
    }

    .signup{
        display: flex;
        gap: 0;
        align-items: center;
        background: #fff;
        padding: 7px;
        font-size: .8rem;
        border-radius: .9375rem;
        box-shadow: var(--shadow1);
    }
   .signup > :first-child {
        font-size: .83rem;

    }
    .wrapper > :nth-child(6){
        display: none;
    }
    .wrapper > :nth-child(7){
        display: flex;
        top: 17rem;
    }
    
    .wrapper > :nth-child(9){
        display: none;
    }
    .wrapper > :nth-child(10){
        display: flex;
        top: 21rem;
        z-index: 999;
    }

}

@media screen and (max-width: 393px) {
    .wrapper > img {
        width: 16.15rem;
        height: 16rem;
        margin-top: .6rem;
        position: absolute;
        left: -2.3rem;

    }

    .wrapper > :nth-child(3){
        position: absolute;
        left: -1.8rem;
        width: 13rem;
        height: 14.8rem;
    }
    .wrapper > :nth-child(9){
       
        display: none;
    }
    .wrapper > :nth-child(10){
        display: none;
    }

    .wrapper > :nth-child(11){
        display: flex;
        top: 20.7rem;
        z-index: 999;
    }
    .customers {
        padding-right: 3rem;
    
    }
    

}


@media screen and (max-width: 375px) {
    
    .wrapper > img {
        width: 12rem;
        height: 14rem;
        margin-top: .6rem;
        position: absolute;
        left: -1.5rem;

    }
    .wrapper > :nth-child(3){
        position: absolute;
        top: 18rem;
        left: -1.85rem;
        width: 12rem;
        height: 13rem;
    }
    .wrapper > :nth-child(11){
        display: none;
    }
    .wrapper > :nth-child(12){
        display: flex;
        top: 18.5rem;
        z-index: 999;
    }

 
    .wrapper >  :nth-child(7){
        display: none;
    }
    .customers {
        padding-right: 4rem;
    
    }


}

@media screen and (max-width: 360px) {
    .wrapper > img {
        width: 12rem;
        height: 13rem;
        margin-top: .6rem;
        position: absolute;
        left: -2.3rem;

    }
    .wrapper > :nth-child(3){
        position: absolute;
        top: 18rem;
        left: -2.85rem;
        width: 12rem;
        height: 13rem;
    }
    .wrapper > :nth-child(12){
        display:none;
    }

    .wrapper > :nth-child(13){
        display:flex;
        top: 20rem;
        z-index: 999;
    }


}




















