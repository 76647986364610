.wrapperImage{
    width: 100vw;
    height: 10%;
    display: flex;
    position: relative;

}



.wrapperImage > img{
    width: 100%;
    height: 33rem;
}

.contactWhy {
    position: absolute;
    top: 5rem;
    left: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contactWhy > :first-child{
    font-size: 3rem;
    font-weight: 800;
    color: #07248c;
}

.contactWhy > :nth-child(2){
    font-size: 1.6rem;


}

/* From Virtual */
.BigContainer{
    position: relative;
    width: 100vw;
    justify-content: center;
    padding: 7rem 5rem 5rem 5rem;

}
.Virtual {
    background: linear-gradient(180deg, rgba(53,49,255,1) 0%, rgba(0,71,255,1) 100%);
    /* padding: 10.7rem; */
    height: 30rem;
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    position: relative;

}

.Virtual .left{
    position: absolute;
    top: 3.5rem;
    left: 16rem;
}

.Virtual .right{
    position: absolute;
    top: 3.5rem;
    right: 16rem;
}

.VirtualText{
    display: flex;
    position: absolute;
    top: -3.5rem;
    font-size: 1.7rem;
    font-weight: 600;

}

.left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.left > :nth-child(1) {
    font-size: 1.6rem;
    letter-spacing: 1px;
    text-transform: capitalize;
    display: block;
    /* font-weight: bold; */

}
.left > :nth-child(2) {
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 30rem;
}

.left > :nth-child(3) {
    font-size: 1.6rem;
    font-weight: bold;
}

.wrapper {
    width: 27rem;
    margin-left: 3rem;
}
.choices{
    display: flex;
    position: relative;
    gap: 3rem;
    margin-top: 3rem;

}

.choiceOne{
    display: flex;
    position: relative;
    flex-direction: column;
}
.choiceOne > :first-child{
    position: absolute;
    left: -1rem;
    bottom: 1.3rem;
    color: rgb(0, 255, 179);
    font-size: 2rem;
    font-weight: bold;
}

.choiceOne > :nth-child(2){
    position: absolute;
    left: 1rem;
    bottom: 1rem;

}

.choiceTwo{
    display: flex;
    position: relative;
    flex-direction: column;
}

.choiceTwo > :first-child{
    position: absolute;
    left: -1rem;
    top: -1.7rem;
    color: rgb(0, 255, 179);
    font-size: 2rem;
    font-weight: bold;
}

.FormSection{
    background-color: #fff;
    display: flex;
    position: relative;


}
.rightSideForm{
    background-color: #fff;
    padding: 6rem 8rem 10rem 10rem;
    color: blue;
    overflow-x: hidden;
    width: 50%;
}

.leftSideForm{
    display: flex;
    position: relative;
    background-color: #051f7b;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.leftSideForm > span{
    width: 50rem;
    padding: 0 4rem 0 7rem;
}



/* --------------------____________-----------___________------___________--------_________ */


.CoursesContainer {
    width: 100vw;
    background: #FFF;
    padding:0rem 4rem;
   
}

.bg_overlay{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    min-width: 55%;
    height: 100%;
    top: 0;
    clip-path: polygon(0 0,100% 0%,75% 100%,0 100%);
    line-height: 2.7rem;
}

.bg_overlay > h1{
    font-size: 2.1rem;
    font-weight: 400;
    color:#051f7b;
    
}



.row {
   display: grid;
   width: 100%;
   grid-template-columns: 60% 40%;
   padding-bottom: 7rem;
  
}
.leftSide{
    display: grid;
    grid-template-columns:  repeat(auto-fill, minmax(200px, 1fr));
    align-items: center;
    padding: 5rem 3rem 8rem 2rem;
    gap: 2rem;

}

.course{
    background-color: #fff;
    color: #051f7b;
    width: 13.375rem;
    height: 8rem;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 0.3125rem;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    gap: .3125rem;
    border: .0938rem solid rgb(235, 237, 240);
}
.course:hover{
    border: .0938rem solid #0027d3;
}
.course > :nth-child(2){
    font-size: 1.1rem;
    font-weight: 500;
}

.course > img {
    vertical-align: middle;
    width: 2.4rem;
    height: 3rem;
    
}

.rightSide{
    display: flex;
    height: 100%;
    padding-left: .5rem;
}

.repair_main_banner {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 2.5rem 0;
}
.repair_main_banner h2{
    font-size: 1.8rem;
    color: #fff;
    margin-left: 2.3rem;
    padding-bottom: 1rem;

}
.progress {
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 27.5%;
    margin-left: 2.3rem;
}

.progress_university {
    --bs-progress-height: .38rem;
    --bs-progress-font-size: .7rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: .375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width .6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    width: 18%;
    margin-left: 2.3rem;
}

.progress_bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);

}




/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:640px) {
    .bg_overlay > h1{
        font-size: 1.3rem;
        font-weight: 400;
        color:#051f7b;
        
    }

}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:530px) {
    .bg_overlay > h1{
        font-size: 1rem;
        font-weight: 600;
        color:#051f7b;
        
    }

}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:425px) {
    .bg_overlay > h1{
        font-size: .8rem;
        font-weight: 600;
        color:#051f7b;
        
    }

}




/* ---------------==============-------------==============-------------========== ========== >>>>>>>>> 1024 px          */ 
@media screen and (max-width:1024px) {
    .bg_overlay > h1{
        font-size: 1.8rem;
        font-weight: 400;
        color:#051f7b;
        
    }

    .rightSideForm{
        padding: 4rem 2.5rem 4rem 4rem;
    }
    .bg_overlay{
        padding-left: .5rem;
    }
    .bg_overlay > h1{
        font-size: 1.4rem;
        font-weight: 400;
        color:#051f7b;
       
    }
}


/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:768px) {

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 400;
        align-items: center;
        justify-content: center;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 6rem 2.5rem 6rem 3rem;
    }
  
    .rightSideForm{
        padding: 4rem 1rem 4rem 0rem;
    }
    .bg_overlay > h1{
        font-size: 1.4rem;
       
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:768px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1.45rem;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 6rem 2.5rem 6rem 3rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:640px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1.15rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
 
    .bg_overlay > h1{
        font-size: 1.2rem;
        font-weight: 400;
        color:#051f7b;
       
    }
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:630px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1.15rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:530px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
    .bg_overlay > h1{
        font-size: 1rem;
        font-weight: 400;
        color:#051f7b;
       
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:500px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:430px) {
    .bg_overlay{
        line-height: 1.7;
    }
    .bg_overlay > h1{
        font-size: .9rem;
        font-weight: 400;
        color:#051f7b;
        padding-right: 1rem;
       
    }
}
/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:425px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
}

@media screen and (max-width:393px) {
    .bg_overlay{
        line-height: 1.7;
    }
    .bg_overlay > h1{
        font-size: .8rem;
        font-weight: 400;
        color:#051f7b;
        padding-right: 1rem;
       
    }
}

/* ---------------==============-------------==============-------------========== */
@media screen and (max-width:375px) {
    .FormSection{
    
        flex-direction: column;
    
    }

    .leftSideForm{
        display: flex;
        position: relative;
        background-color: #051f7b;
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .leftSideForm > span{
        width: 100rem;
        padding: 4rem 1.5rem 4rem 2rem;
    }
  
    .rightSideForm{
        padding: 4rem 1.5rem 4rem 2rem;
        width: 100%;
    }
}


@media screen and (max-width: 360px) {
    .bg_overlay{
        line-height: 1.7;
    }
    .bg_overlay > h1{
        font-size: .78rem;
        font-weight: 400;
        color:#051f7b;
        padding-right: 1rem;
       
    }
}

