.s-container{
    margin-top: 2.5rem;
    padding-bottom: .625rem;
    position: relative;
    color: rgb(19, 116, 197);
  

}

.s-container .swiper{
    width: 70%;
    height: 11rem;
    position: unset;
}

.s-container .swiper-slide {
    background: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev {
    left: 6rem;

}

.s-container .swiper-button-next {
    right: 6rem;

}
.s-container .swiper-button-next, .s-container .swiper-button-prev {
    color:rgb(217, 221, 255);
}



.left-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.name {
    display: flex;
    flex-direction: column;

}

.name > :nth-child(1) {
    font-size: 1.3rem;
    font-weight: 600;
}
.name > :nth-child(2) {
    font-size: 0.9rem;
    color: darkblue;

}

.left-s > :nth-child(2) {
    font-weight: 500;
    font-size: 1.2rem;
}

.left-s > :nth-child(3) {
    font-size: .8125rem;
    border: 1px solid rgb(19, 116, 197);;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

.img-p {
    transform: rotate(0deg);
    position: absolute;
    right: 2rem;
    width: 20%;
    height: 50%;
    bottom: 7%;
}

.arrow-right{
    display: none;
}
@media screen and (max-width:1366px){
    .s-container .swiper{
        width: 57%;
        height: 13rem;
        position: unset;
        margin-top: 5rem;
    }
    /* .s-container .swiper-button-prev,
    .s-container .swiper-button-next{
        display: none;
    } */
}

@media screen and (max-width:1024px){
    .s-container .swiper{
        width: 60%;
        height: 13rem;
        position: unset;
        margin-top: 5rem;
    }


}


@media screen and (max-width: 768px) {
    .s-container{
        margin-top: 2.7rem;
        padding-bottom: 0.2rem;
        position: relative;
    
    }
    .s-container .swiper{
        width: 97%;
        height: 13.5rem;
        position: unset;
        margin-top: 0rem;
    }

    .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}
}

@media screen and (max-width: 640px) {
     .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}
}

@media screen and (max-width: 630px) {
    .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}
}
@media screen and (max-width: 500px) {
    .s-container .swiper{
        width: 90%;
        height: 13.5rem;
        position: unset;
        margin-top: 1rem;
        
    }
    .s-container .swiper-button-prev,
    .s-container .swiper-button-next{
        display: none;
    }

}

@media screen and (max-width: 430px) {
    .s-container{
        margin-top: 1.7rem;
        padding-bottom: 0.2rem;
        position: relative;
    
    }
    .s-container .swiper{
        /* width: 90%; */
        height: 13.2rem;
        position: unset;
        margin-top: 0.7rem;
        margin-bottom: 1.1rem;
        margin-right: 1rem;
    }

    .s-container .swiper-slide {
        background: white;
        display: flex;
        border-radius: 10px;
        padding: 1rem 1rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }

    .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}

    .arrow-right{
        display: flex;
        color: white;
        position: absolute;
        right: 12.1rem;

    }


}


@media screen and (max-width: 425px) {
    .s-container{
        margin-top: 1.7rem;
        padding-bottom: 0.2rem;
        position: relative;
    
    }
    .s-container .swiper{
        /* width: 90%; */
        height: 12.2rem;
        position: unset;
        margin-top: 1rem;
    }

    .s-container .swiper-slide {
        background: white;
        display: flex;
        border-radius: 10px;
        padding: 1rem 1rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }

    .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}


}

@media screen and (max-width: 414px) {
    .s-container .swiper{
        /* width: 90%; */
        height: 12rem;
        position: unset;
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 375px) {
    .swiper-button-next { display: none !important; }
    .swiper-button-prev {display: none !important;}
    
    .arrow-right{
       margin-left: 1.5rem;
    }
}