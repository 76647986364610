@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

 
}

body {
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Axiforma'; */

    color: var(--black);
    scroll-behavior: smooth;
    background: var(--bg);
    background-size: cover;
    transition: all 3ms ease;
}

:root{
    --black: #343434;
    /* --bg: #F8E367; */
    /* --bg: linear-gradient(315deg, #bdd4e7 0%, #8693ab 48%); */
    
    /* --bg: linear-gradient(to right, #4c95fb 0%, #495aff 100%); */
   --bg: linear-gradient(138deg, rgb(72, 161, 255) 36.7%, rgb(0, 8, 187) 84.4%, rgb(255, 255, 255) 119.7%);
    --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
    --shadow2:  0px 13px 46px rgba(0, 0, 0, 0.13);
    --pink: #FE956F;
  }
  .App{
    display: flex;
    flex-direction: column;
  }
  
  
  ::-webkit-scrollbar{
    height: 8px;
    width: 8px;
   
    }
    
    ::-webkit-scrollbar-track{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1ex;
  
    }
    
    ::-webkit-scrollbar-thumb{
    /* background: #d5e7fd; */
    background: #8bbaf3;
    border-radius: 1ex;
    }
  
    a{
      text-decoration: none;
      color: inherit
    }




